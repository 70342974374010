import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql, Link } from "gatsby";

function Layout({ children }) {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <div className="flex flex-col min-h-screen text-orange-400">
          <div className="flex flex-col flex-1 md:justify-center max-w-4xl mx-auto px-4 py-8 md:px-8 md:pb-24 w-full">
            {children}
          </div>
          <footer className="text-right px-4 py-4 md:px-8">
            <Link to="/info">Info </Link>
            //
            <a href="mailto:fuon.chung@gmail.com"> fuon.chung@gmail.com</a>
          </footer>
        </div>
      )}
    />
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
