import React from "react";

import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

function IndexPage({ data }) {
  return (
    <Layout>
      <SEO
        title="Artist Portfolio"
        keywords={[
          `Fu-on Chung`,
          `Fu Chung`,
          `Melbourne Artist`,
          `Artist`,
          `Contemporary Artist`,
          `Contemporary Painter`,
          `Painter`
        ]}
      />

      <h1 className="text-center mb-12 text-5xl font-bold tracking-wide">
        <i>Fu-on Chung</i>
      </h1>
      <ul className="flex text-lg flex-wrap justify-center">
        {data.allContentfulExhibition.edges.map(({ node }, index) => (
          <li className="px-4 leading-loose" key={index}>
            <Link to={node.fields.slug}>{node.title}</Link>
          </li>
        ))}
      </ul>
    </Layout>
  );
}

export default IndexPage;

export const data = graphql`
  query MyQueryNameTwo {
    allContentfulExhibition(sort: { fields: [orderNumber], order: DESC }) {
      edges {
        node {
          title
          fields {
            slug
          }
        }
      }
    }
  }
`;
